@import "../../../styles/media.scss";

.aboutContact {
  border-bottom: 0.3px solid var(--color-grey);

  .filter {
    padding-top: 1rem;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .contactInformation {
    font-style: normal;
    padding: 1rem;

    .externalUrls,
    .contact {
      padding: 1rem 0;

      a {
        padding: 0.25rem 0.625rem;
        background: var(--color-grey-transparent);
        border: 0.3px solid var(--color-grey);
        color: var(--color-grey);
        border-radius: 0.25rem;
        margin: 0 0.125rem;
        svg {
          vertical-align: middle;
        }
        &:hover {
          background-color: #000;
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }

    .contact {
      > div {
        padding-bottom: 1rem;

        div {
          display: block;
        }
      }
    }

    @include desktop() {
      display: flex;
      gap: 20rem;

      .contactsContainer {
        display: flex;
        gap: 5rem;

        .contact {
          padding-top: 0;
        }
      }
    }
  }
}
