.genericFilter {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  .item {
    text-transform: capitalize;
    background: rgba(107, 107, 107, 0.2);
    backdrop-filter: blur(5px);
    border: 0.3px solid #616161;
    color: #616161;
    border-radius: 5px;
    transition: 0.5s;
    display: flex;
    gap: 0;
    align-items: baseline;
    white-space: nowrap;
    &::before {
      content: "";
      width: 0;
      height: 0;
      transform: scale(1);
      background: #fff;
      border-radius: 50%;
      transition: 0.5s;
    }
    &.selected {
      background: #000000;
      border: 0.3px solid #ffffff;
      border-radius: 5px;
      color: #fff;
      gap: 0.5rem;

      &::before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
      }
    }
  }
}

.relativeFilter {
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  z-index: 5;
  .item {
    text-transform: capitalize;
    background: rgba(107, 107, 107, 0.2);
    backdrop-filter: blur(5px);
    border: 0.3px solid #616161;
    color: #616161;
    border-radius: 5px;
    transition: 0.5s;
    gap: 0;
    display: flex;
    align-items: baseline;
    white-space: nowrap;
    &::before {
      content: "";
      width: 0;
      height: 0;
      transform: scale(1);
      background: #fff;
      border-radius: 50%;
      transition: 0.5s;
    }
    &.selected {
      background: #000000;
      border: 0.3px solid #ffffff;
      border-radius: 5px;
      color: #fff;
      gap: 0.5rem;
      &::before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
      }
    }
  }
}
