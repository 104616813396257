.container {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  z-index: 5;
  overflow-x: scroll;
  width: max-content;

  .item {
    text-transform: capitalize;
    background: rgba(107, 107, 107, 0.2);
    backdrop-filter: blur(5px);
    border: 0.3px solid #616161;
    color: #616161;
    border-radius: 5px;
    transition: 0.5s;
    display: flex;
    align-items: baseline;
    gap: 0.25rem;
    z-index: 5;
    overflow-x: auto;

    .item {
      text-transform: capitalize;
      background: rgba(107, 107, 107, 0.2);
      backdrop-filter: blur(5px);
      border: 0.3px solid #616161;
      color: #616161;
      border-radius: 5px;
      transition: 0.5s;
      display: flex;
      align-items: baseline;
    }

    &.selected {
      background: #000000;
      border: 0.3px solid #ffffff;
      border-radius: 5px;
      color: #fff;

      &::before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
      }
    }
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
