@import "../../../styles/media.scss";

.venueDisplay {
  position: relative;
  //width: 400px;
  //border: 10px solid #fff;
  //border-radius: 10px;
  margin: 0 auto 0;
  width: 100vw;
}

.venueDisplay > div:first-child {
  //overflow: hidden;
  width: 100vw;
  margin: 0;
}

.carousel {
  display: flex;
  position: relative;
  width: fit-content;
  padding: 0;
  margin: 0;
  list-style: none;
}

.slide {
  float: left;
  width: 100vw;

  @include media-query(769px, 1024px) {
    width: 50vw;
  }

  @include desktop {
    width: 33.333vw;
  }

  @include desktopLarge {
    width: 25vw;
  }

  //height: 300px;
}

.slide > img {
  height: 500px;
  object-fit: cover;
  width: inherit;
}

.slider {
  height: auto !important;

  img,
  picture {
    height: 100%;
    max-height: 80vh;

    @include media-query(0, 1024px) {
      max-height: 60vh;
    }
  }
}

.navLine {
  display: flex;
  gap: 0.25rem;
}

.item {
  text-transform: capitalize;
  background: rgba(107, 107, 107, 0.2);
  backdrop-filter: blur(5px);
  border: 0.3px solid #616161;
  color: #616161;
  border-radius: 5px;
  transition: 0.5s;
  display: flex;
  align-items: baseline;
  white-space: nowrap;

  &::before {
    content: "";
    width: 0;
    height: 0;
    transform: scale(1);
    background: #fff;
    border-radius: 50%;
    transition: 0.5s;
  }

  &.selected {
    background: #000000;
    border: 0.3px solid #ffffff;
    border-radius: 5px;
    color: #fff;

    &::before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
    }
  }
}

.filter {
  overflow: scroll;
  max-width: 100vw;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-top: 1rem;

  &::-webkit-scrollbar {
    display: none;
  }
}

.imageContainer {
  position: relative;
}

.eventLocationNav {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.scrollContainer {
  overflow-x: scroll;
}

.navButton {
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
